import React from 'react'
import styles from './TeamMember.module.scss'

export default function TeamMember({ fullname, position, shirtNumber, male, image }) {
  let memberImage = image;
  if (!memberImage) {
    memberImage = male === true ? "/assets/images/male.png" : "/assets/images/female.png";
  }

  return (
    <div className={styles.member}>
      <div className={styles.member__image}>
        <img src={memberImage} alt={fullname} />
      </div>
      {shirtNumber && <h1 className={styles.member__shirtNumber}>{shirtNumber}</h1>}
      <p className={styles.member__name}>{fullname}</p>
      <p className={styles.member__position}>{position}</p>
    </div>
  )
}
