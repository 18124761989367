import React from 'react'
// graphql
import { useQuery } from '@apollo/client';
import { GET_PAGE_DATA, GET_ALL_MATCHBALLS } from '../../graphql/queries';
// pages
import Error from '../Error';
// helmet
import { Helmet } from 'react-helmet';
// components
import Loading from '../../components/Elements/Loading';
import Impression from '../../components/Basics/Impression';
import { Link } from 'react-router-dom';

export default function Matchballen() {
  const slug = 'matchballen';

  // --------- QUERY FOR THE PAGE DATA --------- //

  const { loading, error, data } = useQuery(GET_PAGE_DATA, {
    variables: { slug },
  });
  const { loading: loadingMatchballs, error: errorMatchballs, data: dataMatchballs } = useQuery(GET_ALL_MATCHBALLS);
  if (loading || loadingMatchballs) return <Loading />;
  if (error || errorMatchballs) return <Error code='502' text='Invalid response. Please try again later.'/>;

  // --------- PAGE DATA --------- //

  const pageData = data.page;
  const matchballs = dataMatchballs.matchballs;

  // --------- RENDER --------- //

  return (
    <>
      {/* head data */}
      <Helmet>
        <title>{pageData.title}</title>
        <meta name='description' content={pageData.pageDescription}/>
      </Helmet>

      {/* matchbal */}
      <div className='container container--top'>
        <h1 className='container__header'>Matchballen</h1>
        <div className="container__header">
          <h3>Samen maken we voetbal mogelijk</h3>
        </div>
        <p>Het is mogelijk om tijdens een thuiswedstrijd een wedstrijdbal te schenken en F.C. Doggen te ondersteunen. Voor een bijdrage van 100 euro ontvang je:</p>
        <div>
          <span className='list__item'>Gratis toegang voor 5 personen</span>
          <span className='list__item'>De kans om de aftrap op het veld te geven</span>
          <span className='list__item'>Koffie en gebak tijdens de rust</span>
          <span className='list__item paragraph'>Vermelding op de website</span>
        </div>
        <p>Voor meer informatie en een attest kun je terecht bij de <Link to='/contact'>bestuursleden</Link>.</p>

        <div className="container__header">
          <h3>Matchballen</h3>
        </div>
        <div className='matchballen'>
          {matchballs.map((matchball, index) => (
            <div key={index} className='matchball'>
              <Impression url={matchball.matchballImage.url} />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
