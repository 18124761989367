import React from 'react'
import styles from './Result.module.scss'

export default function Result({ homeTeam, awayTeam, homeTeamScore, awayTeamScore, matchDate, competition, comment }) {
  return (
    <div className={styles.result}>
      <div className={styles.result__home}><span>{homeTeam}</span></div>
      <div className={styles.result__score}>
        <div className={styles.result__info}>
          <span>{competition}</span>
          <span className={styles.result__date}>{matchDate}</span>
        </div>
        <h2>{homeTeamScore} - {awayTeamScore}</h2>
        {comment && <p className={styles.result__comment}>{comment}</p>}
        <span className={styles.result__teams}>{homeTeam} - {awayTeam}</span>
      </div>
      <div className={styles.result__away}><span>{awayTeam}</span></div>
    </div>
  )
}
