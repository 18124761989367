import React, { useEffect, useState } from "react";
import styles from "./AnnouncementPopup.module.scss";
import ReactMarkdown from 'react-markdown';

export default function AnnouncementPopup({ title, content }) {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
      const announcementTimestamp = localStorage.getItem('announcementTimestamp');
  
      if (!announcementTimestamp) {
        // Show popup if no timestamp exists
        setShowPopup(true);
      } else {
        const now = new Date().getTime();
        const expirationTime = 4 * 24 * 60 * 60 * 1000; // 4 days in milliseconds
  
        if (now - Number(announcementTimestamp) > expirationTime) {
          // Show popup if 5 days have passed since last shown
          setShowPopup(true);
        }
      }
    }, []);
  
    const handleClose = () => {
      setShowPopup(false);
      // Save the current timestamp in localStorage
      localStorage.setItem('announcementTimestamp', new Date().getTime());
    };
  
    if (!showPopup) return null; // Don't render the popup if not shown
  
    return (
      <div className={styles.overlay}>
        <div className={`${styles.popup} box`}>
            <div className={styles.header}>
                <h3>{title}</h3>
                <button onClick={handleClose} className={styles.button}>
                    <span>X</span>
                </button>
            </div>
            <div className={styles.content}>
                <ReactMarkdown>
                    {content}
                </ReactMarkdown>
            </div>
            <button onClick={handleClose} className={styles.mobileButton}>
                <span className={styles.mobileButton__label}>Sluiten</span>
            </button>
        </div>
      </div>
    );
}