import React, { useState, useEffect, useRef } from 'react';
import styles from './Announcements.module.scss';

export default function Announcements({ announcements }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const autoSlideRef = useRef();

    // Auto-slide functionality
    useEffect(() => {
        if (!isPaused) {
            autoSlideRef.current = setInterval(() => {
                setCurrentIndex((prev) => (prev + 1) % announcements.length);
            }, 5000); // Switch every 5 seconds
        }
        return () => clearInterval(autoSlideRef.current);
    }, [isPaused, announcements.length]);

    // Pause auto-slide on user interaction
    const handleInteraction = () => {
        setIsPaused(true);
        clearInterval(autoSlideRef.current);
        setTimeout(() => setIsPaused(false), 10000); // Resume after 10 seconds
    };

    // Navigate manually
    const nextSlide = () => {
        handleInteraction();
        setCurrentIndex((prev) => (prev + 1) % announcements.length);
    };

    const prevSlide = () => {
        handleInteraction();
        setCurrentIndex((prev) => (prev - 1 + announcements.length) % announcements.length);
    };

    // Navigate via pagination dots
    const goToSlide = (index) => {
        handleInteraction();
        setCurrentIndex(index);
    };

    return (
        <div className={styles.announcements}>
            <button className={`${styles.announcements__arrow} ${styles['announcements__arrow--left']}`} onClick={prevSlide}>
                ←
            </button>
            <div className={styles.announcements__carousel}>
                {announcements.map((announcement, index) => (
                    <div
                        key={index}
                        className={`${styles.announcements__carousel__card} ${
                            index === currentIndex ? styles.active : ''
                        } box`}
                    >
                        {announcement.image && (
                            <div className={styles.card__image}>
                                <img src={announcement.image.url} alt={announcement.title} />
                            </div>
                        )}
                        <div className={styles.card__content}>
                            <h4>{announcement.title}</h4>
                            <p>{announcement.description}</p>
                        </div>
                    </div>
                ))}
            </div>
            <button className={`${styles.announcements__arrow} ${styles['announcements__arrow--right']}`} onClick={nextSlide}>
                →
            </button>
            <div className={styles.announcements__pagination}>
                {announcements.map((_, index) => (
                    <div
                        key={index}
                        className={`${styles.announcements__pagination__dot} ${
                            index === currentIndex ? styles.active : ''
                        }`}
                        onClick={() => goToSlide(index)}
                    ></div>
                ))}
            </div>
        </div>
    );
}